* {
  padding: 0;
  margin: 0;
  font-family: 'Raleway', sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff;
}

.login-panel {
  height: 100vh;

}

.login {
  text-align: center;
}

.loginBtn {
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  padding: 6px 16px;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 16px;
  margin-left: 8%;
  letter-spacing: 1px;
}

.horoscope {
  text-align: right;
  pointer-events: none;
  user-select: none;
}

.horoscope img {
  width: 140%;
  margin: 15% -30% 0 0;
  position: relative;
  transition: 0.70s;
  -webkit-animation: spin 25s linear infinite;
  -moz-animation: spin 25s linear infinite;
  animation: spin 25s linear infinite;

  pointer-events: none;
  user-select: none;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .login-panel {
    height: auto;

  }
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #641E16;

}

.saveBtn {
  background-color: #0abcf9;
  background-image: linear-gradient(315deg, #0abcf9 0%, #2c69d1 74%);
  color: #FFFFFF;
}

.updateBtn {
  color:#ffffff;
  border: orangered;
  background-color: orangered;
}

.cancleBtn {
  background-color: #c62128;
  background-image: linear-gradient(147deg, #c62128 0%, #a00000 74%);
  color: #ffffff;
}

.navText1 {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;

}

.navText1:hover {
  font-weight: 600;
}


.mdeSidebar {
  padding: 6px;
  border-radius: 10px;
  border: 1px solid #EAECEE;
  min-height: 300px;
  box-shadow: 0px 2px 8px rgb(225, 224, 224);
}
.mdeSidebar1 {
  padding: 6px;
  border-radius: 10px;
  border: 1px solid #EAECEE;
  /* max-height: 400px; */
  min-height: 400px;
  box-shadow: 0px 2px 8px rgb(225, 224, 224);
  /* overflow-y: scroll; */
}

.mdes-btn {
  display: flex;
  align-items: center;
  margin: 4px;
  padding: 6px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
  color: #8b0101;
  font-weight: 600;
  margin-bottom: 8px;
  background-size: 100% 200%;
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  -webkit-transition: background-position 1s ease-out;
  -moz-transition: background-position 1s ease-out;
  transition: background-position 1s ease-out;
}

.mdes-btn:hover {
  color: #ffffff;
  background-position: 0 -100%;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);

}

